import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import { graphql } from '@apollo/client/react/hoc';
import { GET_INFOUSER } from '@betwixt/common/src/graphql/user'

class NoMatch extends Component {

    render() {
        const { User } = this.props

        return ( 
            <div>
                { 
                    (User && User.modulesActived.length) 
                        ? <Redirect to={{ pathname: `/${User.modulesActived[1].routes.my.path}` }} />
                        : <Redirect to={{ pathname: '/login' }} /> 
                }
            </div>
        )
    }

}

export default graphql(GET_INFOUSER, { 
    props: ({ data: {User} }) => ({ User })
})(NoMatch)