const typePolicies = {

    _KOT_: {
        fields: {
            History: {
                keyArgs: false
            },
        }
    },

    _KOT_Items_Paginated: {
        keyArgs: false,
        merge(existing, incoming) {
            if (!incoming) return existing
            if (!existing) return incoming

            const { items, ...rest } = incoming;

            let result = rest;
            result.items = [...existing.items, ...items];

            return result
        }
    },

    _KOT_Ticket: {
        keyFields: ['user_name', 'creation_date'],
        fields: {
            items: {
                merge: false
            }
        }
    },

    _KOT_Ticket_Plate: {
        keyFields: ['_id', '_id_ticket']
    }

}
export default typePolicies