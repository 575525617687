import { gql } from '@apollo/client';

export const GET_SETTINGS__CACHE = gql`
    query Settings{
        Settings @client {
            id
            MenuManagement{
                id
                compositionsType
                elementsType
                message
                delete_last_post
                url_menu_on_website
                message_after_menu
                only_preview
                visible_price
                image_post
            }
        }
    }
`;

export const GET_SETTINGS = gql`
    query getSettings{
        Settings{
            id
            MenuManagement{
                id
                compositionsType
                elementsType
                message
                delete_last_post
                url_menu_on_website
                message_after_menu
                only_preview
                visible_price
                image_post
            }
        }
    }
`;

export const UPDATE_SETTINGS = gql`
    mutation updateSettings(
            $moduleId: String!
            $settings: JSONObject!
        ){
            updateSettings(
                moduleId: $moduleId
                settings: $settings
            ){
                MenuManagement{
                    id
                    compositionsType
                    elementsType
                    message
                    delete_last_post
                    url_menu_on_website
                    message_after_menu
                    only_preview
                    visible_price
                    image_post
                }
            }
        }
`;

export const UPLOAD_IMAGE_POST = gql`
        mutation uploadImagePost($file: Upload!){
            uploadImagePost(file: $file) {
                ok
            }
        }
`;
