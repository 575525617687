import React, { useEffect } from 'react'
import { hasSubscriptions } from '../client/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import "../client/scss/index.scss";

const Assistant = props => {

    const { moduleId, User, i18n } = props;

    const resource = require(`@betwixt/common/dist/modules/assistant/translation/${i18n.language}.json`) 
    const _hasSubscriptions_ = hasSubscriptions(User)

    const _t = resource

    if (moduleId === 'shop' && !_hasSubscriptions_)
        return (
            <div id="Assistant">

                <div className="box-assistant first_subscribe">
                    <FontAwesomeIcon icon={faStar} className="fa-1x" />
                    <h4>{ _t.first_subscribe.title }</h4>
                    <p>{ _t.first_subscribe.description }</p>
                </div>

            </div>
        )

    return ('')
}

export default Assistant