const _ = require('lodash')
const { forEach } = require('p-iteration')

async function getDataModules(client) { 

    const GET_MODULES = require('../graphql/modules').GET_MODULES;

    try { 
        // from cache
        return await client.readQuery({ query: GET_MODULES }).Modules;
    } catch (err) { 
        // from server
        return await client.query({ query: GET_MODULES }).then(({data: {Modules}}) => { 
            return Modules
        })
    }
}

const getModulesData = async (client) => {
    // return all modules actived
    const Modules = await getDataModules(client);

    return Modules.map((Module) => {
        if (Module.actived) {
            try {
                const jsonData = require(`@betwixt/common/src/modules/${Module.moduleId}/module.json`)
                return Object.assign({}, Module, jsonData);
            } catch (err) {
                console.log(err)
            }
        }
    });
}

const getModulesActived = async (routes, client) => {
    const Modules = await getDataModules(client);
    
    // return all routes actives for the user    
    return routes.map(item => {
        const Module = _.find(Modules, {moduleId: item});

        if (Module && Module.actived === true) {
            return item;
        } else return false;
    });
}

const extendModules = async (modulesActived) => {
    // CLONATO DA SERVER --- TO DO
    let collection = []
    
    await forEach( modulesActived, async ( moduleId ) => { 
        try {
            const moduleConfig = require(`@betwixt/common/dist/modules/${moduleId}/module.json`)
            collection.push(moduleConfig)
        } catch (err) {
            console.log(err)
        }
    })

    return collection
}

const setSRModules = async (client) => {
    
    //console.log("*************** setSRModules");

    const GET_INFOUSER = require('@betwixt/common/src/graphql/user').GET_INFOUSER;
    const User = await client.query({ query: GET_INFOUSER });
    const modulesActived = User.data.User.modulesActived;
    
    await forEach( modulesActived, async ({ module: {id} }) => {
        try { 
            const schemas = require(`../../dist/modules/${id}/client/schema/default`).default;
            const moduleResolvers = require(`../../dist/modules/${id}/client/resolvers`).default;

            client.cache.writeData({
                data: schemas
            });

            client.addResolvers(moduleResolvers);
            
        } catch (err) {  console.log("error;")}
    })

    //console.log(">>>>>>><client", client)
    /*
    // set Default Schema
    try {
        console.log("[DynamicImport]", this.props)
        var cache = this.props.client.cache.data;
        console.log("cache: ", cache)
    console.log("moduleUrl:",this.props.moduleUrl)
        const defaultSchema = require(`@betwixt/common/${this.props.moduleUrl}/client/schema/default`).default;
        console.log("defaultSchema: ", defaultSchema)
        //const updatedData = Object.assign({}, cache.data, defaultSchema);
        //console.log("updatedData", updatedData);
        this.props.client.cache.writeData({
            data: defaultSchema
        });
        console.log("this.props.client.cache", this.props.client.cache);
    } catch (err) {}

    // set Mutations
    try {
        const resolvers = require(`@betwixt/common/${this.props.moduleUrl}/client/resolvers`).default;
        console.log("resolvers: ", resolvers)
        this.props.client.addResolvers(resolvers);
        console.log("*", this.props.client)
    } catch (err) {}
--------------------------------
                    
    let data = {
        models: {},
        schemas: [],
        resolvers: {}
    }
    const resolvers = require('@betwixt/common/src/resolvers').default;

    await forEach( modulesActived, async ({ module: {id} }) => {
        try { 
            const schemas = require(`../../dist/modules/${id}/client/schema/default`).default;
            const moduleResolvers = require(`../../dist/modules/${id}/client/resolvers`).default.Mutation;

            if (schemas) {
                data['schemas'] = Object.assign({}, data['schemas'], schemas);
                data['resolvers'] = _.merge(data['resolvers'], moduleResolvers);
            }
            
        } catch (err) { }
    })

    data.resolvers = {
        Mutation: Object.assign({}, resolvers.Mutation, data.resolvers)
    }

    return data;
    */
}

const getTypePolicies = () => {

    const ModulesTypePolicies = require('@betwixt/common/src/modules/__TypePolicies.json');
    let typePolicies = require('@betwixt/my/src/typePolicies').default;
    let typePoliciesModules = {}

    ModulesTypePolicies.map((moduleId) => {
        try {
            const tpm = require(`@betwixt/common/src/modules/${moduleId}/client/typePolicies.js`).default
            typePoliciesModules = _.merge(typePoliciesModules, tpm)
        } catch (err) {}
    })

    return _.merge(typePoliciesModules, typePolicies)
}

const getModulePath = ({ module, moduleId, lang }) => {
    if (module && moduleId && lang)
        return `${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}${module.routes.web[moduleId][lang]}`
}

exports.getModulesData = getModulesData;
exports.getModulesActived = getModulesActived;
exports.extendModules = extendModules;
exports.setSRModules = setSRModules;
exports.getTypePolicies = getTypePolicies;
exports.getModulePath = getModulePath;