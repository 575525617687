import React, { lazy, Suspense } from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import {flowRight as compose} from 'lodash'

import { GET_INFOUSER } from '@betwixt/common/src/graphql/user'

const ModulesEmitters = props => {

    const { User } = props

    const getAllListening = () => {
        if (User && User.modulesActived) {
            return User.modulesActived.map( (item, index) => { 
                if(item.module && item.module.options && item.module.options.emitters && item.module.options.emitters === true) {
                    
                    try {
                        const Folder = item.routes && item.routes.my.path ? item.routes.my.path : item.module.id;
                        const Component = lazy( () => import(`@betwixt/common/dist/modules/${Folder}/web/Emitters`) )
                        /*
                        const resource = require(`@betwixt/common/dist/modules/${Folder}/translation/${i18n.language}.json`)    
                        i18n.addResourceBundle(i18n.language, `${item.module.id}`, resource, true, true)
                        */
                        return (
                            <div className={`emitters ${item.module.id}-emitters`} key={index}> 
                                <Component key={index} {...props} {...item} /*_t={(key) => i18n.getResource(i18n.language, `${item.module.id}`, key)}*/ />
                            </div>
                        )
                    } catch (err) {
                        console.log(err)
                    }

                }
                return null
            })
        }
    }

    return (
        <div>
            <Suspense fallback={<div>Loading...</div>}> 
                { 
                    getAllListening() 
                } 
            </Suspense>
        </div>
    )

}
export default compose(
    graphql(GET_INFOUSER, {
        props: ({ data: { User } }) => ({ User })
    })
)(withApollo(ModulesEmitters))