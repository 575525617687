import React, { Component } from 'react'

import DynamicImport from './DynamicImport'
import Loading from '../Loading'

class CreateComponent extends Component{

    render(){
        
        const { moduleId, endpointComponent, moduleConfig, moduleUrl, pagename, t, i18n, client, history, gConfig, User } = this.props 

        return ( 
            <DynamicImport moduleId={moduleId} 
                           pagename={pagename} 
                           moduleUrl={moduleUrl}
                           moduleConfig={moduleConfig}
                           t={t}
                           i18n={i18n}
                           client={client} 
                           history={history}
                           gConfig={gConfig}
                           User={User}
                           load={() => import(`@betwixt/common/dist/modules/${endpointComponent}`)} 
                           >
                {
                    (Component) => Component === null
                        ? <Loading type="ring"/>
                        : <Component {...this.props} />
                }
            </DynamicImport>
        )
    }
}
export default CreateComponent