import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc';

import { SDK } from '@betwixt/common'
import { OAuth } from '@betwixt/common/src/utils/auth'

import ModulesEmitters from './components/ModulesEmitters'
import Loading from './components/Loading'
import Portal from './components/Portal'

import Error from './components/Error'
import Router from './components/router'

class App extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loaded: false
        }
    }

    componentDidMount = async () => { 
        
        SDK.init();
        const check = await OAuth.init(this.props.client)

        if (check)
            this.setState({
                loaded: true
            })
    }

    render() { 
        if (this.state.loaded)
            return (
                <div className="App">

                    <ModulesEmitters { ...this.props } />
                    
                    <Error client={this.props.client} />

                    <Router gConfig={this.props.gConfig} />

                    <Portal />

                </div>
            )
        
        return (
            <Loading type="ring"/>
        )
    }
}

export default withApollo(App)