exports.formatPrice = (price, locale, currency) => {
    if (!price)
        return;
    if (!locale)
        locale = 'it-IT';
    if (!currency)
        currency = 'EUR'

    return (price).toLocaleString(locale, {
        style: 'currency',
        currency: currency,
    })
}

exports.activatingSticky = (parentInfo, offsetTop) => {

    let ref = null
    let scrollTop = 0
    let response = false
    let headerOffsetHeight = (document.getElementById('Header')) ? document.getElementById('Header').offsetHeight : false

    if (parentInfo)
        ref = parentInfo.getBoundingClientRect()

    if (ref)
        scrollTop = (ref.y < 0) ? -ref.top : 0;

    if (headerOffsetHeight)
        response = {
            class: (ref && ref.top < -offsetTop && ref.height > (-ref.top + headerOffsetHeight)) ? 'sticky' : ''
        }
    else
        response = {
            class: (ref && ref.top < -offsetTop && ref.height > ref.top) ? 'sticky' : ''
        }

    response['scrollTop'] = scrollTop

    return response
}