import i18next from "i18next";
import LngDetector from 'i18next-browser-languagedetector';

import global_en from './global_en.json';
import global_it from './global_it.json';

import nav_en from './nav_en.json';
import nav_it from './nav_it.json';

import login_en from './login_en.json';
import login_it from './login_it.json';

import signup_en from './signup_en.json';
import signup_it from './signup_it.json';

import checkout_en from './checkout_en.json';
import checkout_it from './checkout_it.json';

i18next
    .use(LngDetector)
    .init({
        detection: {
            // order and from where user language should be detected
            order: ['localStorage', 'querystring', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
          
            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
          
            // cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
          
            // optional expire and domain for set cookie
            cookieMinutes: 10,
            cookieDomain: 'betwixtpro.com',
          
            // optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement
        },
        interpolation: { escapeValue: false }, 
        //lng: 'it',   
        //fallbackLng: 'it',
        lngs: ['it', 'en'],
        whitelist: ['it', 'en'],
        resources: {
            en: {
                global: global_en,
                nav: nav_en,
                signup: signup_en,
                login: login_en,
                checkout: checkout_en
            },
            it: {
                global: global_it,
                nav: nav_it,
                signup: signup_it,
                login: login_it,
                checkout: checkout_it
            },
        },
    });