const SET_ERROR = require('@betwixt/common/src/graphql/error').SET_ERROR

const SetError = ( type, title, content, client, history ) => {
    //alert("TODO error quando cancello una sottoscrizione e tento di accedere ad un modulo")
    client.mutate({
        mutation: SET_ERROR,
        variables: {
            thisWrong: true,
            type,
            title, 
            content
        }
    }).then((res) => {  
        if( type == 'inPAGE' )
            history.push('/error')
    })

}

const ResetError = ( client ) => {
    
    client.mutate({
        mutation: SET_ERROR,
        variables: {
            thisWrong: false,
            type: 'null',
            title: 'null', 
            content: 'null'
        }
    })

}

const SetErrorsToState = (errors, $this) => {
    const e = {};
    errors.forEach(({ path, message }) => {
        e[`${path}Error`] = message
    });
    $this.setState( { errors : e } )
}

exports.SetError = SetError
exports.ResetError = ResetError
exports.SetErrorsToState = SetErrorsToState