module.exports = {

    isMobile: () => isMobile(),

    getOrSetDefault_Aside_isVisible: (state) => { 
        if (typeof localStorage != undefined && typeof localStorage != 'undefined') {
            if (typeof state != "undefined") {
                localStorage.setItem('aside_isVisible', state)
            } else {
                const ls_aside_isVisible = localStorage.getItem('aside_isVisible')
                
                if (!ls_aside_isVisible){
                    const value = (isMobile()) ? false : true
                    localStorage.setItem('aside_isVisible', value)
                }
                return ls_aside_isVisible != null ? ls_aside_isVisible == 'true' : null
            }
        } 

        return false
    },

    updatePageData: async (index, value, client) => {
        localStorage.setItem('aside_isVisible', value)
    }

}

function isMobile() {
    const w = typeof window != undefined ? global.window : false
    return (w) ? w.innerWidth < 768 : false
}