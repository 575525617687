import { gql } from '@apollo/client';

export const GET_SETTINGS__CACHE = gql`
    query Settings{
        Settings @cache{
            id
            _KOT_{
                id
                status
                calendar_add_value
                calendar_add_type
                calendar_mode
                hours_opening_reservation
                message_creation
                message_update
                message_time_expired
            }
        }
    }
`;

export const GET_SETTINGS = gql`
    query getSettings{
        Settings{
            id
            _KOT_{
                id
                status
                calendar_add_value
                calendar_add_type
                calendar_mode
                hours_opening_reservation
                message_creation
                message_update
                message_time_expired
            }
        }
    }
`;

export const UPDATE_SETTINGS = gql`
    mutation updateSettings(
            $moduleId: String!
            $settings: JSONObject!
        ){
            updateSettings(
                moduleId: $moduleId
                settings: $settings
            ){
                _KOT_{
                    id
                    status
                    calendar_add_value
                    calendar_add_type
                    calendar_mode
                    hours_opening_reservation
                    message_creation
                    message_update
                    message_time_expired
                }
            }
        }
`;
