import { gql } from '@apollo/client';

export const ModuleParts = {
    fragments: {
        Module: gql`
            fragment ModuleParts on Module{
                moduleId
                custom_position
                external_id
                plan
                actived
            }
        `,
        detailsModule: gql`
            fragment detailsModule on detailsModule{
                id
                languages
                position
                options {
                    showInMenu
                    showInModulesList
                    settings
                    dashboard {
                        status
                    }
                    emitters
                    dependences
                }
            }
        `,
        fullDetailsModule: gql`
            fragment fullDetailsModule on Module{
                module{
                    id
                    languages
                    position
                    options {
                        showInMenu
                        showInModulesList
                        settings
                        dashboard {
                            status
                        }
                        emitters
                        dependences
                    }
                }
                component{
                    id
                }
                routes{
                    my{
                        path
                        icon{
                            fontawesome
                            href
                        }
                        secondary{
                            component{
                                id
                            }
                            routes{
                                path
                                icon{
                                    fontawesome
                                    href
                                }
                            }
                        }
                    }
                    web
                }
            }
        `,
        Subscription: gql`
            fragment SubscriptionDetailParts on SubscriptionDetail{
                external_id
                plan_id
                cancel_at_period_end
                current_period_end
                current_period_start
                status
                invoiceId
                plans{
                    id
                    moduleId
                    active
                    subscribeActived
                    nickname
                    amount
                    interval
                    trial_period_days
                    metadata
                }
            }
        `
    }
}

export const GET_MODULE = gql`
    query getModule($moduleId: String!){
        Module(moduleId: $moduleId){
            ...ModuleParts
            subscription{
                ...SubscriptionDetailParts
            }
            routes{
                web
            }
        }
    }
    ${ModuleParts.fragments.Module}
    ${ModuleParts.fragments.Subscription}
`

export const GET_MODULES = gql`
    query getModules{
        Modules{
            ...ModuleParts
            module{
                ...detailsModule
            }
            routes{
                web
            }
        }
    }
    ${ModuleParts.fragments.Module}
    ${ModuleParts.fragments.detailsModule}
`

export const GET_MODULES_PLANS = gql`
    query getModules{
        Modules{
            ...ModuleParts
            module{
                ...detailsModule
            }
            subscription{
                ...SubscriptionDetailParts
            }
            routes{
                web
            }
        }
    }
    ${ModuleParts.fragments.Module}
    ${ModuleParts.fragments.detailsModule}
    ${ModuleParts.fragments.Subscription}
`

export const CHECK_MODULE_IS_ACTIVED = gql`
    query checkModuleIsActived( $moduleId: String! ){
        checkModuleIsActived( moduleId: $moduleId ){
            ok
            errors{
                path
                message
            }
        }
    }
`
