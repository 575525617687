import React from 'react'
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

import './client/scss/NavsAsideExtends.scss'

const NavsAsideExtends = props => {

    const { User, i18n, ManageAsideView } = props
    const URLPublicPage = `${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/public/${User.companyname}/menu/view`

    return (
        <div id="NavsAsideExtends_MM">
            <a href={URLPublicPage}
                target="_blank"
                className="btn btn--public-page">
                { i18n.getResource(i18n.language, 'menu-management', 'navsaside.publicpage') }
            </a>
            <Link to="/menu-management/qrcode" className="btn btn--qr-code" onClick={() => ManageAsideView(false)}>
                <FontAwesomeIcon className={'mr-1'} icon={faQrcode} /> { i18n.getResource(i18n.language, 'menu-management', 'navsaside.qrcode') }
            </Link>
        </div>
    )
}
export default NavsAsideExtends