import React, { Component } from 'react';

import { 
    ApolloProvider,
    ApolloClient, 
    InMemoryCache,
    ApolloLink,
    split, 
    HttpLink,
    //makeVar
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import possibleTypes from '@betwixt/my/src/possibleTypes.json'

import App from './App'
import Loading from '@betwixt/my/src/components/Loading'
import { getTypePolicies } from '@betwixt/common/src/utils/modules'

import { message } from 'antd';

/*
 * makeVars
 */



/*
 * Cache
 */
class Cache extends Component {

    constructor(props) {
        super(props)

        this.state = {
            client: null,
            loaded: false
        }
    }

    componentDidMount = async () => {
        const middlewareLink = setContext(() => ({
            headers: {
                'x-token': localStorage.getItem('token'),
                'x-refresh-token': localStorage.getItem('refreshToken')
            }
        }))
        const afterwareLink = new ApolloLink((operation, forward) => {
            //console.log("**** afterwareLink : ", operation, operation.getContext())
            
            const { headers } = operation.getContext()
        
            if (headers) {
                const token = headers.get('x-token')
                const refreshToken = headers.get('x-refresh-token')
        
                if (token) {
                    localStorage.setItem('token', token)
                }
        
                if (refreshToken) {
                    localStorage.setItem('refreshToken', refreshToken)
                }
            }
        
            return forward(operation)
        })
        const errorLink = onError(({ graphQLErrors, networkError }) => {
            //console.log("**** errorLink : ", graphQLErrors, networkError)
        
            if (graphQLErrors)
                graphQLErrors.forEach(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
                    )
                );

            if (networkError) {
                message.error(`Network error: ${networkError.Message}`);
                //console.log(`[Network error]: ${networkError}`);
            }
        
            if (process.env.NODE_ENV !== 'development') {
                //OAuth.logout();
                console.log(graphQLErrors, networkError)
            } else {
                console.log("logout user on production/test environment")
                console.log(graphQLErrors, networkError)
                alert("[GraphQL error]")
            }
        })
        
        const httpLink = new HttpLink({ 
            uri: this.props.gConfig.GraphQL.uri 
        })
        const wsLink = new GraphQLWsLink(createClient({
            url: global.gConfig.WebSocket.uri
        }))
        const httpLinkExtended = errorLink.concat(afterwareLink.concat(middlewareLink.concat(httpLink)))
        const link = split(
            ({ query }) => {
              const definition = getMainDefinition(query);
              return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
              );
            },
            wsLink,
            httpLinkExtended,
        )
        
        let cache = new InMemoryCache({
            possibleTypes,
            typePolicies: getTypePolicies()
        })

        const client = new ApolloClient({
            cache: cache,
            link: link,
            connectToDevTools: this.props.gConfig.config_id === 'development'
        });
        
        localStorage.removeItem('SRLoadedModules')

        this.setState({
            client,
            loaded: true,
        });
    }

    render() {
        const { client, loaded } = this.state;

        if (!loaded) {
            return <Loading type="ring"/>
        }

        return (
            <ApolloProvider client={client}>
                <App gConfig={this.props.gConfig} />
            </ApolloProvider>
        );
    }
}

export default Cache