import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'

import { withI18n } from 'react-i18next'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import {flowRight as compose} from 'lodash'

import { GET_INFOUSER, LOGIN_MUTATION } from '@betwixt/common/src/graphql/user'

import Header from '../components/Header'
import Footer from '../components/Footer'

import { OAuth } from '@betwixt/common/src/utils/auth'

class Login extends Component {

    constructor(props) {
        super(props)

        this.state = {
            username: null,
            password: null,
            errors: {}
        } 
    }

    componentDidMount(){
        if (this.props && this.props.User)
            this.props.history.push(`/${this.props.User.modulesActived[1].routes.my.path}`)
    }

    UNSAFE_componentWillUpdate(nextProps){ 
        if(nextProps.User){ 
            this.props.history.push(`/${nextProps.User.modulesActived[1].routes.my.path}`)
            //nextProps.history.goBack() //this.props.history.push('/dashboard')
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.User && JSON.stringify(nextProps.User) !== JSON.stringify(this.props.User) || nextState.errors !== this.props.errors)
            return true
        return false
    }

    handleSubmit = async (e) => {

        e.preventDefault();

        if(this.state.username === null || this.state.password === null)
           return

        await OAuth.automaticLogin(this)
    }

    render() {
        return ( 
            <main id="LoginPage" role="main">

                <Header pagename="login" />
                
                {this.state.errors.formError != null ? (
                    <div className="error"> {this.state.errors.formError} </div>
                ) : ('')}

                <section data-pagename="login">
                    <div className="container section primary mt-0">
                        <div className="row">

                            <div className="col-md-12 col-xs-12">

                                <div id="LoginPage" className="section col-md-12 col-sm-12 col-xs-12">
                                    <header>
                                        <h3> {this.props.t('section.title')} </h3>
                                        <p> {this.props.t('section.description')} </p>
                                    </header>

                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row">

                                            <div className="form-group col-md-5">
                                                <input
                                                    id="username"
                                                    name="username"
                                                    type="text"
                                                    className="form-control with-border"
                                                    placeholder={ this.props.i18n.getResource(this.props.i18n.language, 'global', 'username') }
                                                    onChange={(e) => this.setState({ username: e.target.value })}
                                                />
                                                {this.state.errors.emailError != null ? (
                                                    <div className="error"> {this.state.errors.emailError} </div>
                                                ) : ('')}
                                            </div>

                                            <div className="form-group col-md-5">
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    className="form-control with-border"
                                                    placeholder={ this.props.i18n.getResource(this.props.i18n.language, 'global', 'password') }
                                                    onChange={(e) => this.setState({ password: e.target.value })}
                                                />
                                                {this.state.errors.passwordError != null ? (
                                                    <div className="error"> {this.state.errors.passwordError} </div>
                                                ) : ('')}
                                            </div>

                                            <div className="form-group col-md-2">
                                                <button type='submit' className="btn btn-primary mb-2 w-100">GO</button>
                                            </div>

                                            {this.state.errors.genericError != null ? (
                                                <div className="col-md-12 mb-4">
                                                    <div className="error genericError"> {this.state.errors.genericError} </div>
                                                </div>
                                            ) : ('')}

                                        </div>
                                    </form>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div style={{textAlign: 'center', margin: '30px'}}>
                        <NavLink to='/signup'> { this.props.i18n.getResource(this.props.i18n.language, 'global', 'signup_2') } </NavLink>
                    </div>

                </section>

                <Footer i18n={this.props.i18n} without_logo={true} type="minimal_row"/>
            </main>
        )
    }
}

export default compose(
    graphql(GET_INFOUSER, {
        props: ({ data: { User } }) => ({ User })
    }),
    graphql(LOGIN_MUTATION)
)( withRouter( withI18n() (withApollo( withNamespaces('login')(Login) ))))