import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc';

import { GET_ERROR } from '@betwixt/common/src/graphql/error'

import Modal from '@betwixt/common/dist/components/Modal'
import { ResetError } from '@betwixt/common';

class Error extends Component {
    render(){
        
        const { Error, client } = this.props

        if( Error && Error.thisWrong && Error.type === 'inMODAL' ) 
            return <Modal title={Error.title} body={Error.content} className={`ERROR ${Error.type}`} callback={ () => ResetError(client) } /> 
        else 
            return false
    }
}

export default graphql(GET_ERROR, {
    props: ({ data: { Error } }) => ({ Error })
}) (Error)