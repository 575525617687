import { gql } from '@apollo/client';
import { ModuleParts } from './modules';
import { SubscriptionParts } from './subscriptionPlan';

export const UserParts = {
    fragments: {
        User: gql`
            fragment UserParts on User{
                clientId
                username
                companyname
                roles
            }
        `,
        fullUser: gql`
            fragment fullUserParts on User{
                id
                clientId
                firstname
                lastname
                roles
                email
                username
                companyname
                address
                vat_number
                phone
                company_email
            }
        `,
        PaymentMethods: gql`
            fragment PaymentMethodParts on PaymentMethod{
                id
            }
        `
    }
}

export const SET_INFOUSER = gql`
    mutation setInfoUser(
        $obj: JSONObject!
    ) {
        setInfoUser(
            obj: $obj
        ) @client {
            __typename
        }
    }
`;

export const GET_INFOUSER = gql`
    query User{
        User @client {
            ...UserParts
            modulesActived{
                ...ModuleParts
                ...fullDetailsModule
            }
            subscriptions{
                ...SubscriptionParts
                module{
                    ...ModuleParts
                }
            }
        }
    }
    ${UserParts.fragments.User}
    ${ModuleParts.fragments.Module}
    ${ModuleParts.fragments.fullDetailsModule}
    ${SubscriptionParts.fragments.Subscription}
`;

export const GET_PUBLIC_USERINFO = gql`
    query getPublicUserInfo($companyname: String, $clientId: String){
        getPublicUserInfo(companyname: $companyname, clientId: $clientId) {
            user {
                ...fullUserParts
            }
        }
    }
    ${UserParts.fragments.fullUser}
`;

export const GET_DETAILS = gql`
    query User{
        User {
            ...fullUserParts
            payment_methods{
                ...PaymentMethodParts
            }
        }
    }
    ${UserParts.fragments.fullUser}
    ${UserParts.fragments.PaymentMethods}
`;

export const GET_USERINFO_CHECKOUT = gql`
    query User{
        User {
            clientId
            email
            payment_methods{
                ...PaymentMethodParts
            }
        }
    }
    ${UserParts.fragments.PaymentMethods}
`;

export const REFRESH_USER_DETAILS = gql`
    query User{
        User {
            ...UserParts
            modulesActived{
                ...ModuleParts
                ...fullDetailsModule
            }
            subscriptions{
                ...SubscriptionParts
                module{
                    ...ModuleParts
                }
            }
            payment_methods{
                ...PaymentMethodParts
            }
        }
    }
    ${UserParts.fragments.User}
    ${ModuleParts.fragments.Module}
    ${ModuleParts.fragments.fullDetailsModule}
    ${SubscriptionParts.fragments.Subscription}
    ${UserParts.fragments.PaymentMethods}
`;

export const USER_WITH_TOKEN = gql`
    mutation userFromToken
        (
            $clientId: String!
        )
        {
            userFromToken
            (
                clientId: $clientId,
            )
            {
                ok
                user{
                    ...UserParts
                    modulesActived{
                        ...ModuleParts
                        ...fullDetailsModule
                    }
                    subscriptions{
                        ...SubscriptionParts
                        module{
                            ...ModuleParts
                        }
                    }
                }
                errors{
                    path
                    message
                }
            }
        }
        ${UserParts.fragments.User}
        ${ModuleParts.fragments.Module}
        ${ModuleParts.fragments.fullDetailsModule}
        ${SubscriptionParts.fragments.Subscription}
`

export const REGISTER = gql`
    mutation register
        (
            $firstname: String!
            $lastname: String!
            $companyname: String!
            $email: String!
            $username: String!
            $password: String!
            $roles: Int!
            $recaptcha: String!
            $urlparams: String
        )
        {
            register
            (
                firstname: $firstname,
                lastname: $lastname,
                companyname: $companyname,
                email: $email,
                username: $username,
                password: $password,
                roles: $roles,
                recaptcha: $recaptcha
                urlparams: $urlparams
            )
            {
                ok
                errors{
                    path
                    message
                }
            }
        }

`;

export const UPDATE = gql`
    mutation update
        (
            $firstname: String
            $lastname: String
            $email: String
            $username: String
            $password: String
            $roles: Int
            $companyname: String
            $address: String
            $vat_number: String
            $phone: String
            $company_email: String
        )
        {
            update
            (
                firstname: $firstname,
                lastname: $lastname,
                email: $email,
                username: $username,
                password: $password,
                roles: $roles
                companyname: $companyname,
                address: $address,
                vat_number: $vat_number,
                phone: $phone,
                company_email: $company_email,
            )
            {
                ok
                errors{
                    path
                    message
                }
                user {
                    ...fullUserParts
                }
            }
        }
        ${UserParts.fragments.fullUser}
`;

export const LOGIN_MUTATION = gql`
    mutation login
        (
            $username: String!, 
            $password: String!,
            $flow: String
        ) 
        {
            login
            ( 
                username: $username, 
                password: $password 
                flow: $flow
            ) 
            {
                ok
                token
                refreshToken
                user{
                    ...UserParts
                    modulesActived{
                        ...ModuleParts
                        ...fullDetailsModule
                    }
                    subscriptions{
                        ...SubscriptionParts
                        module{
                            ...ModuleParts
                        }
                    }
                }
                errors{
                    path
                    message
                }
                
            }
        }
        ${UserParts.fragments.User}
        ${ModuleParts.fragments.Module}
        ${ModuleParts.fragments.fullDetailsModule}
        ${SubscriptionParts.fragments.Subscription}
`;

