import React, { Component, Fragment } from 'react'
import { withI18n } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import {flowRight as compose} from 'lodash'

import { GET_INFOUSER } from '@betwixt/common/src/graphql/user'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { OAuth } from '@betwixt/common/src/utils/auth'
import { isMobile } from '@betwixt/common/src/utils/page'

import NavsAside from './NavsAside'
import { getOrSetDefault_Aside_isVisible } from '@betwixt/common/src/utils/page'

import '@betwixt/common/src/scss/navs.scss'

class Navs extends Component {

    constructor(props) {
        super(props)

        this.state = {
            type: null,
            routes: {
                primary: [],
                secondary: []
            },
            Page: {
                aside_isVisible: getOrSetDefault_Aside_isVisible()
            }
        }

        this._isMounted = false;
        this.renderItemMenu = this.renderItemMenu.bind(this)
    }

    componentDidMount(){
        this._isMounted = true;
        this.updateParams()
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    UNSAFE_componentWillReceiveProps(){
        this.updateParams()
    }

    /*
    shouldComponentUpdate(){
        if( 
            JSON.stringify(this.props.User.modulesActived) !== JSON.stringify(this.state.routes.primary) 
            ||
            ( 
                this.props.moduleConfig 
                && 
                this.props.moduleConfig.routes 
                && 
                this.props.moduleConfig.routes.secondary 
                && 
                JSON.stringify(this.props.moduleConfig.routes.secondary) !== JSON.stringify(this.state.routes.secondary)
            )
        )
            return true
        return false
    }
    */

    updateParams(){
        const type = this.props.navType || 'ASIDE'
        const modulesActived = (this.props.User && this.props.User.modulesActived) ? this.props.User.modulesActived : false
        const primary = modulesActived || []
        const secondary = (
                            this.props.moduleConfig 
                            && 
                            this.props.moduleConfig.routes 
                            && 
                            this.props.moduleConfig.routes.my.secondary
                          ) 
                            ? this.props.moduleConfig.routes.my.secondary 
                            : []
        
        //console.log("[NAVS][", type, "]", primary, secondary, menuItems, this.props)

        if (this._isMounted) {
            this.setState({
                type, 
                routes:{
                    primary,
                    secondary
                }
            })
        }
    }

    back(){
        if (this._isMounted) {
            this.setState({ 
                ...this.state,
                routes:{
                    primary: this.state.routes.primary,
                    secondary: []
                }
            }, () => {
                // remove assistant-module-opened
                const bodyRoot = document.getElementsByTagName('body');
                if (bodyRoot[0].classList.contains('assistant-module-opened'))
                    bodyRoot[0].classList.remove('assistant-module-opened')
            })
        }
    }

    getIcon = (module) => {

        let icon = false
        let path = false

        try {
            icon = module.routes.my.icon
            path = module.routes.my.path
        } catch (err) {
            icon = module.routes.icon
            path = module.routes.path
        }

        if (icon) {
            if (icon.href) {
                const _icon_ = require(icon.href)
                return <img src={_icon_} alt={path} />
            }
            if (icon.fontawesome) {
                const _icon_ = require('@fortawesome/free-solid-svg-icons')[icon.fontawesome]
                return <FontAwesomeIcon icon={_icon_} /> 
            }
        }
        return false
    }
    
    ManageAsideView(status) {
        let newStatus = status

        if (isMobile() && this.state.Page.aside_isVisible) {
            newStatus = false;
            if (this._isMounted) {
                this.setState({
                    ...this.state,
                    Page: {
                        aside_isVisible: newStatus
                    }
                }, () => this.props.updatedNavsAside(newStatus))
            }
        } else {
            requestAnimationFrame(() => {
                if (this._isMounted) {
                    this.setState({
                        ...this.state,
                        Page: {
                            aside_isVisible: newStatus
                        }
                    }, () => this.props.updatedNavsAside(newStatus))
                }
            })
        }
        
        getOrSetDefault_Aside_isVisible(newStatus)
    }

    renderItemMenu = (routes, navType, pagename) => { 

        //console.log("[NAVS] renderItemMenu| routes:", routes, "navType: ", navType, "pagename: ", pagename)
        
        return routes.map( (item, index) => { 
        
            //console.log("[NAVS] renderItemMenu | item: ", item, " (index):", index)

            try {
                if (item.module && item.module.options && !item.module.options.showInMenu)
                    return false;
            } catch (e) {}

            try{
                if( navType === 'primary' || navType === 'secondary'){
                    
                    let icon = null
                    let item_resource = "NotFound"
                    let key_translation = null
                    const key_obj_translation = pagename || 'nav'

                    try {
                        key_translation = `primaryMenu.${item.module.id}` 
                    } catch (e) {
                        key_translation = `section.menu.${item.component.id}`
                    }

                    item_resource = this.props.i18n.getResource(this.props.i18n.language, key_obj_translation, key_translation)
                    icon = (this.state.type === 'ASIDE') ? this.getIcon(item) : ''

                    if (!icon && !item_resource)
                        return ('')

                    return <li
                            key={index.toString()}
                            data-key={index.toString()}
                            className={`ns-${item.component.id}`}
                            > 
                                <NavLink onClick={() => this.ManageAsideView(this.state.Page.aside_isVisible)} to={`${(navType === 'primary') ? `/${item.routes.my.path}` : `/${pagename}/${item.routes.path}`}`}> 
                                    { icon }
                                    <span dangerouslySetInnerHTML={{ __html: item_resource }} />
                                </NavLink>
                            </li>
                }

                if( navType === 'tertiary' )
                    return <li
                            key={index.toString()}
                            data-key={index.toString()}
                            className={`ns-${item.component.id}`}
                            onClick={ () => (this.props[`callbackItem${index+1}`]) ? this.props[`callbackItem${index+1}`]() : null }
                            > 
                                {item} 
                            </li>
            } catch(err) { 
                //console.log("[MODULE] ERROR ROUTES") 
                //console.log(err) 
            }

            return false;
        })
    }

    render() {
        // => props : User, navType, pagename, sectionTitle, offsetTopSticky, withoutSticky, parentInfo, moduleConfig, style
        const { User, navType, pagename, sectionTitle, parentInfo, style, NavsAsideExtendedComponent } = this.props

        // *****************************************
        // ************ section PRIMARY ************
        // *****************************************
        if(navType === 'PRIMARY'){
            return (
                <Fragment>
                    <div id="togglePrimaryMenu" className={(this.props.primaryMenu_Mobile_Opened) ? 'opened' : ''} onClick={() => this.props.toggleMenu() }>
                        <div /> <div /> <div />
                    </div>
                    
                    <nav id="primaryMenu" className={(this.props.primaryMenu_Mobile_Opened) ? 'opened' : ''}>
                        {
                            (User === undefined) ? (
                                <ul> 
                                    {
                                        (pagename === 'login') && (
                                            <li id="btn-signup"> <NavLink to='/signup'> { this.props.i18n.getResource(this.props.i18n.language, 'global', 'signup') } </NavLink> </li> 
                                        )
                                    }
                                    {
                                        (pagename === 'signup') && (
                                            <li id="btn-signup"> <NavLink to='/login'> { this.props.i18n.getResource(this.props.i18n.language, 'global', 'login') } </NavLink> </li> 
                                        )
                                    }
                                </ul>
                            ) : (
                                <ul>
                                    { this.renderItemMenu( this.state.routes.primary, 'primary' ) }
                                    <li id="btn-logout" onClick={ () => OAuth.logout( this.props.client, this.props.history ) }>  Logout  </li>
                                </ul>
                            )
                        }
                    </nav>

                    {
                        this.props.primaryMenu_Mobile_Opened && (
                            <div id="darkflow" onClick={() => this.props.toggleMenu() }></div>
                        )
                    }

                </Fragment>
            )
        }

        // *****************************************
        // ************ section SECONDARY **********
        // *****************************************
        if(navType === 'SECONDARY'){ 
            if(this.state.routes.secondary.length){
                return (
                    <div className="col-md-3 col-xs-12 pl-0 pr-0">
                        <nav id="secondaryMenu">
                            <ul>
                                { this.renderItemMenu( this.state.routes.secondary, 'secondary', pagename ) }
                            </ul>
                        </nav>
                    </div>
                )
            }else{
                return (
                    <div> </div>
                )
            }
        }
        
        // *****************************************
        // ************ section TERTIARY ***********
        // *****************************************
        if(navType === 'TERTIARY'){ 
            if(this.props.menuItems){
                /*
                let sticky = activatingSticky(parentInfo, 250);
                let style = {
                    'top': `${sticky.scrollTop}px`
                }
                */
                return (
                    //<div className={`container_sticky ${sticky.class}`} style={style} >
                    <div className={`container_sticky`}>
                        <nav className="tertiaryMenu container">
                            <h1> {sectionTitle} </h1>
                            <ul>
                                { this.renderItemMenu( this.props.menuItems, 'tertiary', pagename ) }
                            </ul>
                        </nav>
                    </div>
                )
            }else{
                return ('')
            }
        }

        // *****************************************
        // ************* section ASIDE *************
        // *****************************************
        if(navType === 'ASIDE' || navType === undefined){ 
            return (
                <NavsAside User={User} 
                           routes={this.state.routes} 
                           pagename={pagename} 
                           back={() => this.back()}
                           parentInfo={parentInfo}
                           renderItemMenu={(routes, navType, pagename) => this.renderItemMenu(routes, navType, pagename)}
                           isVisible={this.state.Page.aside_isVisible}
                           ManageAsideView={(status) => this.ManageAsideView(status)}
                           client={this.props.client}
                           history={this.props.history}
                           style={style}
                           t={this.props.t}
                           i18n={this.props.i18n}
                           getIcon={(item) => this.getIcon(item)}
                           NavsAsideExtendedComponent={NavsAsideExtendedComponent}
                           />
            )
        }
        
        
    }
}

export default compose(
    graphql(GET_INFOUSER, {
        props: ({ data: { User } }) => ({ User })
    })
)(withApollo( withI18n()(Navs) ))