import React from 'react'
import { Switch, Route } from 'react-router-dom'

import i18n from 'i18next'

import { useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'

import { GET_INFOUSER } from '@betwixt/common/src/graphql/user'

import PrivateRoute from './PrivateRoute'
import CreateComponent from './CreateComponent'
import Loading from '../Loading'

import SignupComponent from '../../views/Signup'
import LoginComponent from '../../views/Login'
import NoMatchComponent from '../../views/NoMatch'

const ManageRoutes = props => { 

    const { client, history, gConfig } = props

    const { loading, data } = useQuery(GET_INFOUSER)
    
    const User = data && data.User || false

    if (loading)
        return <Loading type="ring" />
    
    return(
        <Switch>
            <Route exact path='/login' component={() => <LoginComponent gConfig={gConfig} />} />
            <Route path='/signup' component={() => <SignupComponent gConfig={gConfig} />}  />
            {
                (data && User && User.modulesActived)
                    ? createPrivateItemsRoute('SECONDARY_ROUTES', User, client, history, gConfig)
                    : ''
            }
            {
                (data && User && User.modulesActived)
                    ? createPrivateItemsRoute('ROUTES', User, client, history, gConfig)
                    : ''
            }
            <Route path='/error' component={() => <NoMatchComponent gConfig={gConfig} />} />
            <Route component={() => <NoMatchComponent User={User} gConfig={gConfig} />} />
        </Switch>
    )
}

export default withApollo(ManageRoutes)

const createPrivateItemsRoute = (type, User, client, history, gConfig) => { 
    
    return User.modulesActived.map((item, index) => {
        
        if (type === 'ROUTES') {
            return createPrivateRoute(type, index, item, item, User, client, history, gConfig)
        } else
        if (type === 'SECONDARY_ROUTES') {
            if (item.routes.my && item.routes.my.secondary !== null && item.routes.my.secondary.length) {
                return item.routes.my.secondary.map( (secondary_item, secondary_index) => { 
                    return createPrivateRoute(type, secondary_index, secondary_item, item, User, client, history, gConfig)
                })
            }
        } 
        return false;
    })
}

const createPrivateRoute = (type, index, item, firstItem, User, client, history, gConfig) => { 

    try{
        const moduleConfig = (firstItem) ? firstItem : item
        const moduleId     = (item.module) ? item.module.id : firstItem.module.id
        const componentId  = item.component.id
        const routerPath   = (firstItem) ? firstItem.routes.my.path : item.routes.my.path
        const moduleUrl    = `dist/modules/${routerPath}`
        const endPoint     = item.routes ? item.routes.path ? item.routes.path : componentId : componentId

        let endpointComponent;
        let privateRoutePath;

        if(type === 'SECONDARY_ROUTES'){
            endpointComponent = `${routerPath}/routes/web/${componentId}`
            privateRoutePath = `/${routerPath}/${endPoint}`
        } else 
        if(type === 'ROUTES'){
            endpointComponent = `${routerPath}/web/${componentId}`
            privateRoutePath = `/${routerPath}`
        }
        /*
        console.log("[router][createRoute]: ", " | ", moduleId, " | ", componentId, " | ", moduleConfig, " | ", routerPath, " | ", endpointComponent, " | ", privateRoutePath, " | ", moduleUrl)
        */

        const cComponent = (props) => (
            <CreateComponent {...props} 
                        endpointComponent={endpointComponent} 
                        pagename={moduleId}
                        moduleId={moduleId} 
                        moduleConfig={moduleConfig} 
                        moduleUrl={moduleUrl}
                        User={User}
                        client={client} 
                        history={history}
                        i18n={i18n}
                        t={i18n.getFixedT(null, moduleId)}
                        gConfig={gConfig}
                        >
                {(Component) => Component === null
                        ? <Loading type="ring"/>
                        : <Component {...this.props} />}
            </CreateComponent>
        )
        
        return <PrivateRoute key={index.toString()} 
                            path={privateRoutePath} 
                            component={cComponent}
                            User={User}
                            i18n={i18n}
                            />
    } catch (err){
        console.log(err)
    }
}