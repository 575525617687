import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter } from 'react-router-dom'

import { message } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'swiper/swiper-bundle.min.css';
import './scss/root.scss'

import Cache from './Cache'

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import "./translations"

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

require('@betwixt/common/src/config/config.js')

ReactDOM.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18next}>
            <Cache gConfig={global.gConfig} />
        </I18nextProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
    onUpdate: () => {
        message.success({ content: i18next.getResource(i18next.language, 'global', 'serviceWorker.onUpdate')})
        window.location.reload()
    },
    onSuccess: () => message.success({ content: i18next.getResource(i18next.language, 'global', 'serviceWorker.onSuccess')})
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
