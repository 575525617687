import React, { Component, Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import Loading from '@betwixt/my/src/components/Loading'

import '../scss/modal.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

class cModal extends Component {

    constructor() {
        super()

        this._isMounted = true

        this.state = {
            show: false
        }

        this.onHide = this.onHide.bind(this)
        this.onExited = this.onExited.bind(this)
    }

    componentDidMount(){
        if (this.props && this.props.hasOwnProperty('controlFromCTA'))
            this.setState({ show: true })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ show: nextProps.show })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onHide() {
        this.setState({ show: false }, () => {
            if (this.props.onHide)
                this.props.onHide()
        })
    }

    onExited() {
        if (this.props.onExited)
            this.props.onExited()
    }

    render() {

        const { className, dialogClassName, hiddenClose, title, header, body, children, footer, loading } = this.props
        const isLoading = loading === true;

        return (
            <Modal className={`${className} ${isLoading && 'loading'}`}
                   dialogClassName={dialogClassName}
                   show={this.state.show} 
                   onHide={() => this.onHide()}
                   onExited={() => this.onExited()}
                   backdrop={this.props.backdrop || true}
                >

                
                {
                    isLoading && (
                        <div className="loading">
                            <Loading type="ring"/>
                        </div>
                    )
                }

                <Modal.Header closeButton={!hiddenClose}>
                    { title && <Modal.Title> {title} </Modal.Title> }
                    {header}
                </Modal.Header>

                {
                    children ? children : (
                        <Fragment>
                            <Modal.Body> 
                                {body} 
                            </Modal.Body>
                            <Modal.Footer>
                                {footer}
                            </Modal.Footer>
                        </Fragment>
                    )
                }
                
            </Modal>
        )

    }

}
export default cModal