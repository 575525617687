const typePolicies = {

    Query: {
        fields: {}
    },
    User: {
        keyFields: ["clientId"]
    },
    Module: {
        keyFields: ["moduleId"]
    },
    Error: {
        keyFields: false
    },
    detailsRoutes: {
        merge(existing, incoming) {
            return { ...existing, ...incoming };
        }
    },

}
export default typePolicies