import React from 'react'

const LanguageSelector = props => {

    const { i18n } = props

    const changeLanguage = targetLng => {
        i18n.changeLanguage(targetLng).then(() => 
            window.location.reload()
        );
    }

    return (
        <div id="languageSelector">
            <span className="label">{ i18n.getResource(i18n.language, 'global', 'changelang') }</span>
            <button className={i18n.language === 'it' ? 'actived' : ''} onClick={() => changeLanguage('it')}> it </button>
            <button className={i18n.language === 'en' ? 'actived' : ''} onClick={() => changeLanguage('en')}> en </button>
        </div>
    );
}

export default LanguageSelector