import React from 'react';
import SVG from 'react-svg'

import LanguageSelector from './LanguageSelector'
import logo from '@betwixt/common/src/assets/images/logo.svg'
import '@betwixt/common/src/scss/footer.scss';

const Footer = props => {

    const { i18n, without_logo, without_language, without_privacylink, type } = props

    return (
        <div id="footer" className={`container ${type}`}>
            {
                !without_logo && (
                    <div style={without_language || without_privacylink ? { justifyContent: 'center', order: 1 } : {} }>
                        <a href={`${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}`}> 
                            <SVG src={logo} className="logo"/> 
                        </a>
                    </div>
                )
            }
            <div className="container-menu" style={without_language || without_privacylink ? { justifyContent: 'center', order: 0 } : {} }>
                {
                    !without_language && (
                        <nav> <LanguageSelector i18n={i18n} /> </nav>
                    )
                }
                {
                    !without_privacylink && (
                        <nav>
                            <a href={`${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/privacy-policy`}> 
                                Privacy Policy
                            </a>
                            <a href={`${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/cookie-policy`}> 
                                Cookie Policy
                            </a>
                        </nav>
                    )
                }
            </div>
        </div>
    )
}

export default Footer