import React, { Component } from 'react'
import SVG from 'react-svg'

import Navs from './Navs'

import '@betwixt/common/src/scss/header.scss'
import logo from '@betwixt/common/src/assets/images/logo.svg'

/**
# Overview
This is my project overview.
*/

class Header extends Component {

    constructor(props){
        super(props)

        this.maxWidthMobile = 768
        this.offsetTopSticky = 150

        this.toggleMenu = this.toggleMenu.bind(this)
        this.checkScrollY = this.checkScrollY.bind(this)
        this.check_Window_For_Resize = this.check_Window_For_Resize.bind(this)

        this.state = {
            headerInSticky: false,
            primaryMenu_Mobile_Opened: false
        }
    }

    componentDidMount(){
        if(!this.props.withoutSticky)
            window.addEventListener('scroll', this.checkScrollY)

        this.check_Window_For_Resize();
        window.addEventListener('resize', this.check_Window_For_Resize)
    }

    componentWillUnmount(){
        if(!this.props.withoutSticky)
            window.removeEventListener('scroll', this.checkScrollY)
        
        window.removeEventListener('resize', this.check_Window_For_Resize)
    }

    check_Window_For_Resize() {
        if( window.innerWidth > this.maxWidthMobile && this.state.primaryMenu_Mobile_Opened )
            this.toggleMenu()
    }

    checkScrollY(){
        const headerInSticky = window.scrollY > this.offsetTopSticky
        
        if (headerInSticky)
            document.body.classList.add('headerInSticky')
        else
            document.body.classList.remove('headerInSticky')

        this.setState( { ...this.state, headerInSticky } )
    }

    toggleMenu = () => { 
        this.setState({
            ...this.state,
            primaryMenu_Mobile_Opened: !this.state.primaryMenu_Mobile_Opened
        })
    }

    render() {
        const { pagename, withoutLogo, withoutNavs } = this.props

        return (
            <header className={`container header ${ (this.state.headerInSticky) ? 'sticky' : '' } ${ this.state.primaryMenu_Mobile_Opened ? 'primaryMenu_Mobile_Opened' : '' }`} data-pagename={pagename}>
                <div className="container d-flex flex-row justify-content-between">
                    {
                        ( withoutLogo === true )
                            ? ''
                            : <a href={`${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}`}> 
                                <SVG src={logo} id="logo" className={this.state.primaryMenu_Mobile_Opened ? 'beWhite' : ''} /> 
                            </a>
                    }

                    {   
                        ( withoutNavs === true )
                            ? ''
                            : <Navs navType="PRIMARY" pagename={pagename} primaryMenu_Mobile_Opened={this.state.primaryMenu_Mobile_Opened} toggleMenu={ () => this.toggleMenu() } /> 
                    }
                </div>
            </header>
        )
    }
}

export default Header