const decode = require('jwt-decode')
const getModulesActived = require('@betwixt/common').getModulesActived
const CHECK_MODULE_IS_ACTIVED = require('@betwixt/common/src/graphql/modules').CHECK_MODULE_IS_ACTIVED
const GET_INFOUSER = require('@betwixt/common/src/graphql/user').GET_INFOUSER
const USER_WITH_TOKEN = require('@betwixt/common/src/graphql/user').USER_WITH_TOKEN
const SetErrorsToState = require('@betwixt/common').SetErrorsToState
const SetError = require('@betwixt/common').SetError

const OAuth = {

    init: async (client, moduleId) => {

        /**
        * @param {string} client - this is client prop
        * @param {string} moduleId - this is moduleId 
        * @return {object} result status ok: true|false
        */
        const tokenData = await OAuth.getTokenData(client);

        if(tokenData.ok){
            
            if(moduleId && tokenData.clientId){
                const thisModuleIsValid = await client.query({
                    query: CHECK_MODULE_IS_ACTIVED,
                    variables: { moduleId }
                }).then((res) => res.data.checkModuleIsActived)

                if( !thisModuleIsValid.ok )
                    return thisModuleIsValid
            }

            await OAuth.setClientData(client, tokenData.clientId, tokenData.ok)

            return { ok: true }
        }

        return { ok: false }
    },

    getTokenData: async (client) => {

        const token = OAuth.getToken();
        const refreshToken = OAuth.getRefreshToken();

        if (!token || !refreshToken) {
            return { ok: false, clientId : null }
        }

        try {
            const { exp } = decode(refreshToken);

            var expDate = new Date(0)
                expDate.setUTCSeconds(exp)

            const isValid = expDate > new Date()
            
            if ( !isValid ) {
                return { ok: false, clientId : null }
            }
        } catch (err) {
            return { ok: false, clientId : null }
        }

        const { user: { clientId, modulesActived } } = decode(token)

        return { 
            ok: true, 
            clientId,
            modulesActived: await getModulesActived(modulesActived, client)
        }
    },

    setClientData: async (client, clientId, isAuthenticated) => {

        const User = await client.query({
            query: GET_INFOUSER
        }).then((res) => res.data.User)
        
        if ( !User ) {

            try{
                await client.mutate({
                    mutation: USER_WITH_TOKEN,
                    variables: { 
                        clientId 
                    },
                    /*
                    optimisticResponse: {
                        userFromToken: {
                            __typename: 'Mutation',
                            ok: true
                        }
                    },
                    */
                    update: (store, { data: { userFromToken } }) => { 
                        if(userFromToken.ok){

                            let data = store.readQuery({ query: GET_INFOUSER })

                                if (!data) {
                                    data = {}
                                    data.User = {}
                                }
                                
                                data.User.__typename = 'User'
                                data.User.id = userFromToken.user.id
                                data.User.clientId = userFromToken.user.clientId
                                data.User.username = userFromToken.user.username
                                data.User.companyname = userFromToken.user.companyname
                                data.User.roles = userFromToken.user.roles
                                data.User.modulesActived = userFromToken.user.modulesActived
                                data.User.subscriptions = userFromToken.user.subscriptions

                            store.writeQuery({ query: GET_INFOUSER, data })
                            
                            return { _typename: data.User.__typename }
                        }
                    }
                })
            } catch(err){
                console.log(err)
            }
        }
    },

    getToken: () => {
        return localStorage.getItem('token');
    },

    getRefreshToken: () => {
        return localStorage.getItem('refreshToken');
    },

    logout: async (client, history) => {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        location.href = '/login'

        if (client)
            await client.cache.gc();
    },

    automaticLogin: async ($this, flow) => {
        
        $this.props.mutate({
            variables: {
                username: $this.state.username,
                password: $this.state.password,
                flow: flow
            }, 
            /*
            optimisticResponse: {
                userFromToken: {
                    __typename: 'LoginResponse',
                    ok: true
                }
            },
            */
            update: (store, { data: { login } }) => { 
                if(login.ok){

                    let data = store.readQuery({ query: GET_INFOUSER })

                        if (!data) {
                            data = {}
                            data.User = {}
                        }

                        data.User.__typename = 'User'
                        data.User.id = login.user.id
                        data.User.clientId = login.user.clientId
                        data.User.username = login.user.username
                        data.User.companyname = login.user.companyname
                        data.User.roles = login.user.roles
                        data.User.modulesActived = login.user.modulesActived
                        data.User.subscriptions = login.user.subscriptions

                    store.writeQuery({ query: GET_INFOUSER, data })

                    return { _typename: data.User.__typename }
                }
            }
        })
        .then( async ({ data }) => {
            if(data.login.ok){
                localStorage.setItem('token', data.login.token)
                localStorage.setItem('refreshToken', data.login.refreshToken)
                $this.props.history.push(`/${data.login.user.modulesActived[1].routes.my.path}${$this.props.location.search}`)
            }else{
                SetErrorsToState(data.login.errors, $this)
            }
        }).catch((err) => {
            SetError( 
                "inMODAL",
                'Network error', 
                err.message,
                $this.props.client,
                $this.props.history
            )
        });

    }

}
exports.OAuth = OAuth