import { gql } from '@apollo/client';
import { ModuleParts } from '@betwixt/common/src/graphql/modules'

export const SubscriptionParts = {
    fragments: {
        Subscription: gql`
            fragment SubscriptionParts on SubscriptionPlan{
                external_id
                plan_id
                cancel_at_period_end
                current_period_end
                current_period_start
                status
                invoiceId
            }
        `
    }
}

export const CANCELING_SUBSCRIPTION = gql`
    mutation cancelingSubscription(
        $subscription_id: String!
    ){
        cancelingSubscription(
            subscription_id: $subscription_id
        ){
            ok
            error{
                path
                message
            }
            subscription{
                ...SubscriptionParts
                module{
                    ...ModuleParts
                    subscription{
                        ...SubscriptionDetailParts
                    }
                }
            }
        }
    }
    ${SubscriptionParts.fragments.Subscription}
    ${ModuleParts.fragments.Module}
    ${ModuleParts.fragments.Subscription}
`;

export const REACTIVATING_SUBSCRIPTION = gql`
    mutation reactivatingSubscription(
        $subscription_id: String!
    ){
        reactivatingSubscription(
            subscription_id: $subscription_id
        ){
            ok
            error{
                path
                message
            }
            subscription{
                ...SubscriptionParts
                module{
                    ...ModuleParts
                    subscription{
                        ...SubscriptionDetailParts
                    }
                }
            }
        }
    }
    ${SubscriptionParts.fragments.Subscription}
    ${ModuleParts.fragments.Module}
    ${ModuleParts.fragments.Subscription}
`;

export const PAY_SUBSCRIBE = gql`
    mutation paySubscribe(
        $email: String!
        $paymentMethod_id: String
        $invoice_id: String
        $items: JSONObject
    ){
        paySubscribe(
            email: $email
            paymentMethod_id: $paymentMethod_id
            items: $items
            invoice_id: $invoice_id
        ){
            ok
            status_subscription
            status_paymentintent
            invoice_id
            paymentIntentSecret
            pending_setup_intent{
                client_secret
                status
            }
            latest_invoice{
                id
            }
            subscription{
                ...SubscriptionParts
                module{
                    ...ModuleParts
                    subscription{
                        ...SubscriptionDetailParts
                    }
                }
            }
            user{
                clientId
                modulesActived{
                    ...ModuleParts
                    ...fullDetailsModule
                }
            }
            error{
                path
                message
            }
        }
    }
    ${SubscriptionParts.fragments.Subscription}
    ${ModuleParts.fragments.Module}
    ${ModuleParts.fragments.Subscription}
    ${ModuleParts.fragments.fullDetailsModule}
`;

export const CONFIRM_SUBSCRIBE = gql`
    mutation confirmSubscribe(
        $invoice_id: String!
    ){
        confirmSubscribe(
            invoice_id: $invoice_id
        ){
            ok
            subscription{
                ...SubscriptionParts
                module{
                    ...ModuleParts
                    subscription{
                        ...SubscriptionDetailParts
                    }
                }
            }
            user{
                clientId
                modulesActived{
                    ...ModuleParts
                    ...fullDetailsModule
                }
            }
            error{
                path
                message
            }
        }
    }
    ${SubscriptionParts.fragments.Subscription}
    ${ModuleParts.fragments.Module}
    ${ModuleParts.fragments.Subscription}
    ${ModuleParts.fragments.fullDetailsModule}
`;