const hasSubscriptions = (User) => User.subscriptions.length !== 0;

const pulseOnElement = (container) => {
    if (!container)
        return;
    
    document.querySelectorAll('.pulse').forEach(e => e.remove());

    const el_pulse = container.getElementsByClassName('pulse')
    if (!el_pulse.length) {
        container.classList.add('assistant-selected')
        var el = document.createElement('div');
            el.classList.add('pulse');
            el.addEventListener('click', () => removePulseOnElement(container))
        container.appendChild(el)
    }
}
const removePulseOnElement = (container) => {
    if (!container)
        return;
        
    const pulseEl = container.getElementsByClassName('pulse')
    if (pulseEl.length) {
        container.classList.remove('assistant-selected')
        pulseEl[0].remove()
    }
}

module.exports = {
    hasSubscriptions: hasSubscriptions,
    pulseOnElement: pulseOnElement,
    removePulseOnElement: removePulseOnElement
}