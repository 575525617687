import { gql } from '@apollo/client';

export const GET_SETTINGS__CACHE = gql`
    query Settings{
        Settings @cache{
            id
            _DEMO_{
                key1
                key2
                key3
            }
        }
    }
`;

export const GET_SETTINGS = gql`
    query getSettings{
        Settings{
            id
            _DEMO_{
                key1
                key2
                key3
            }
        }
    }
`;

export const UPDATE_SETTINGS = gql`
    mutation updateSettings(
            $moduleId: String!
            $settings: JSONObject!
        ){
            updateSettings(
                moduleId: $moduleId
                settings: $settings
            ){
                _DEMO_{
                    key1
                    key2
                    key3
                }
            }
        }
`;
