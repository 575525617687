import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

class PrivateRoute extends Component {
    render(){

        const { component: Component, User, i18n, ...rest } = this.props

        return ( 
            <Route
                {...rest}
                render= { props =>
                    ( User ? (
                        <Component i18n={i18n} {...props} />
                    ) : (
                        <Redirect to={{ pathname: '/login' }} />
                    )) }
            /> 
        )
    }
}
export default PrivateRoute