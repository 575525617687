import React from 'react'
import './client/scss/NavsAsideExtends.scss'


const NavsAsideExtends = props => {

    const { t, i18n } = props

    return (
        <div id="NavsAsideExtends_DEMO">
            <div className="btn--demo"></div>
        </div>
    )
}
export default NavsAsideExtends