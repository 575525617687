import React, { Component } from 'react'
import '../scss/loading.scss'

class Loading extends Component {

    Elements() {
        let StyleCircle = {};
        const { borderColor } = this.props
        
        if (borderColor) {
            StyleCircle = {
                borderColor: `${borderColor} transparent transparent transparent`
            }
        }

        return (
            <span>
                <div style={StyleCircle}></div>
                <div style={StyleCircle}></div>
                <div style={StyleCircle}></div>
                <div style={StyleCircle}></div>
            </span>
        )
    }

    render(){

        let Style = {};
        let { type, dimension, inline } = this.props

        if (!type)
            type = 'ring'

        if (dimension) {
            Style = {
                'height' : dimension + 'px', 
                'width': dimension + 'px'
            }
        }

        if (inline) {
            Style = {
                ...Style,
                display: 'inline-block',
                margin: '0 10px',
                height: '17px',
                width: '17px'
            }
        }
        
        if(type === 'ring') {
            return(
                <div className="lds-ring" style={Style}>{this.Elements()}</div>  
            )
        }

        if(type === 'ellipsis') {
            return(
                <div className="lds-ellipsis" style={Style}>{this.Elements()}</div>  
            )
        }
    }
}

export default Loading