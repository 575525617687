import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { OAuth } from '@betwixt/common/src/utils/auth'

import '../scss/navsAside.scss'

class NavsAside extends Component {
    
    getSecondaryRoutes(){
        if(this.props.routes.secondary)
            return this.props.routes.secondary
            //return this.props.routes.secondary.filter( (m) => m.component.id !== 'settings') 
    }

    render() {
        
        const { User, routes, pagename, back, isVisible, renderItemMenu, client, history, style, i18n, getIcon, ManageAsideView, NavsAsideExtendedComponent } = this.props

        // TO DO console.log( _.find(this.state.routes.primary, { 'routes': {'path': } }) )
        
        const secondaryRoutes = this.getSecondaryRoutes(routes.secondary)
        const PrimaryModule = (pagename) ? require(`@betwixt/common/dist/modules/${pagename}/module.json`) : false;
        
        /*
        let sticky = activatingSticky(parentInfo, 22);
        let style = {
            'top': `${sticky.scrollTop}px`
        }
        */
       
        return (
            //<aside id="sidebar" className={`${(isVisible) ? 'isVisible' : ''} ${sticky.class}`}  style={style}> 
            <aside id="sidebar" className={`${(isVisible) ? 'isVisible' : ''}`} style={style}> 
            
                <div className={`containerSidebar ${(isVisible) ? 'isVisible' : ''}`}>
                    <header>
                        <div id="controllerAside">
                            <ul>
                                <li id="toggleAside"
                                    className={`${(isVisible) ? 'opened' : 'closed'}`}
                                    onClick={() => ManageAsideView( !isVisible ) }>                                    
                                    <div /> <div /> <div />
                                </li>
                            </ul>
                        </div>
                        {
                            (User) ? (
                                <section id="profile"> 
                                    <div className="imageProfile"> <FontAwesomeIcon icon={faUser} /> </div>
                                    <div className="content"> 
                                        <div className="name_profile"> {User.username} </div>
                                        <div className="companyname_profile"> {User.companyname} </div>
                                        <div className="edit_profile" onClick={() => ManageAsideView(false)}> <NavLink to='/profile'> Edit Profile </NavLink> </div>
                                    </div>
                                    <div className="cta">
                                        <a href="true" onClick={ () => OAuth.logout( client, history ) }>  Logout  </a>
                                    </div>
                                </section>
                            ) : ''
                        }
                    </header>

                    <div id="container_menu">
                        {
                            (secondaryRoutes.length) 
                                ?
                                    /* SECONDARY MENU */
                                    <nav> 
                                        <ul>
                                            <li>
                                                <div className="btn_back" onClick={ () => back() }>
                                                    <FontAwesomeIcon icon={faChevronLeft} />
                                                </div> 
                                            </li>
                                            <li id="primaryModule" className={`ns-${PrimaryModule.module.id}`} onClick={() => ManageAsideView(isVisible) }> 
                                                <NavLink to={`/${PrimaryModule.routes.my.path}`}> 
                                                    { getIcon(PrimaryModule) }
                                                    <span dangerouslySetInnerHTML={{ __html: i18n.getResource(i18n.language, 'nav', `primaryMenu.${PrimaryModule.module.id}`) }} />
                                                </NavLink>
                                            </li>
                                            { renderItemMenu( secondaryRoutes, 'secondary', pagename ) }
                                        </ul>
                                    </nav>
                                :   
                                    /* PRIMARY MENU */
                                    <nav>
                                        {
                                            (User === undefined) ? (
                                                <ul> 
                                                    <li className="explore-btn"> <NavLink to='/modules'> modules </NavLink> </li> 
                                                    <li className="btn-login"> <NavLink to='/login'> login </NavLink> </li> 
                                                </ul>
                                            ) : (
                                                <ul>
                                                    { renderItemMenu( routes.primary, 'primary' ) }
                                                </ul>
                                            )
                                        }
                                    </nav>
                        }
                        {
                            NavsAsideExtendedComponent && secondaryRoutes.length > 0 && (
                                <NavsAsideExtendedComponent {...this.props} />
                            )
                        }
                    </div>

                </div>
 
                {
                    isVisible && (
                        <div id="darkflow" onClick={() => ManageAsideView(false) }></div>
                    )
                }
    
            </aside>
        )
        
    }
}

export default NavsAside