export * from './src/utils/error.js'
export * from './src/utils/modules.js'
export * from './src/utils/page.js'
export * from './src/utils/utils.js'

export const SDK = {
    init : () => {
        
        const pageUtil = require('@betwixt/common/src/utils/page')
        pageUtil.getOrSetDefault_Aside_isVisible()

    }
}