const typePolicies = {

    _MM_: {
        fields: {
            Menus: {
                merge(existing, incoming) {
                    if (!existing) return incoming
                    return [...existing, incoming]
                }
            },

            Compositions: {
                keyArgs: args => {
                    if (args.filter !== undefined)
                        return ['filter']
                    return false
                }
            },
            Elements: {
                keyArgs: args => {
                    if (args.filter !== undefined)
                        return ['filter']
                    return false
                }
            },

        }
    },

    _MM_Menu: {
        keyFields: ['_id'],
        fields: {
            items: {
                merge(existing, incoming) {
                    return incoming
                }
            }
        }
    },

    _MM_Compositions_Paginated: {
        keyArgs: false,
        merge(existing, incoming) {
            if (!incoming) return existing
            if (!existing) return incoming

            const { items, ...rest } = incoming;

            let result = rest;
            result.items = [...existing.items, ...items];

            return result
        }
    },

    _MM_Elements_Paginated: {
        keyArgs: false,
        merge(existing, incoming) {
            if (!incoming) return existing
            if (!existing) return incoming

            const { items, ...rest } = incoming;

            let result = rest;
            result.items = [...existing.items, ...items];

            return result
        }
    },

    _MM_Composition:{
        keyFields: ['_id']
    },

    _MM_Element:{
        keyFields: ['_id']
    }

}
export default typePolicies