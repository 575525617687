import { gql } from '@apollo/client';

export const SET_ERROR = gql`
    mutation setError(
        $type: String!,
        $thisWrong: Boolean!, 
        $title: String!,
        $content: String!
    ) {
        setError(
            type: $type,
            thisWrong: $thisWrong, 
            title: $title,
            content: $content
        ) @client {
            type
            thisWrong
            title
            content
        }
    }
`;

export const GET_ERROR = gql`
    query getError{
        Error @client {
            type
            thisWrong
            title 
            content
        }
    }
`;
