import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { withRouter } from 'react-router'
import ReCAPTCHA from "react-google-recaptcha";

import { graphql, withApollo } from '@apollo/client/react/hoc'
import {flowRight as compose} from 'lodash'

import { REGISTER, LOGIN_MUTATION } from '@betwixt/common/src/graphql/user'

import Modal from '@betwixt/common/dist/components/Modal'
import Header from '../components/Header'
import Footer from '../components/Footer'

import { OAuth } from '@betwixt/common/src/utils/auth'

import '../scss/register.scss';

const SetErrorsToState = require('@betwixt/common').SetErrorsToState

class Signup extends Component {

    constructor(props){
        super(props)

        this.state = {
            firstname: '',
            lastname: '',
            companyname: '',
            email: '',
            username: '',
            password: '',
            roles: 2,
            errors: {},
            recaptcha: '',
            validate: false
        }
    }

    getParamsFromURL() {
        const params = this.props.location.search;
        if (params.indexOf('goto') !== -1)
            return params.replace('?', '&')
        return ''
    }

    handleSubmit = async (e) => {
        e.preventDefault()

        if (this.validateForm()) {
            this.props.REGISTER({
                variables: {
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    companyname: this.state.companyname,
                    email: this.state.email,
                    username: this.state.username,
                    password: this.state.password,
                    roles: this.state.roles,
                    recaptcha: this.state.recaptcha,
                    urlparams: this.getParamsFromURL()
                }
            })
            .then( async ({ data }) => {
                if (data.register.ok) {
                    await OAuth.automaticLogin(this, 'register')
                } else {
                    SetErrorsToState(data.register.errors, this)
                }
            })
        }
    }

    componentDidUpdate() {
        var isValid = this.validateForm()

        if (isValid !== this.state.validate) {
            this.setState({
                validate: isValid
            })
        }
    }

    validateForm() {
        return (
            this.state.firstname && 
            this.state.lastname && 
            this.state.companyname && 
            this.state.email && 
            this.state.username && 
            this.state.password
        ) ? true : false
    }

    resetForm() {
        this.setState({
            ...this.state,
            firstname: '',
            lastname: '',
            companyname: '',
            email: '',
            username: '',
            password: '',
            recaptcha: '',
            errors: {}
        })
    }

    onChange = async (recaptchaValue) => {
        this.setState({
            ...this.state,
            recaptcha: recaptchaValue
        })
    }

    ManageModal(type) {
        if (type === 'visible') {
            let visible = false;
            if (this.state.errors.genericError !== undefined)
                visible = true;
            else if (this.state.errors.recaptchaError !== undefined)
                visible = true;
    
            return visible;
        } else
        if (type === 'body') {
            let body = '';
            if (this.state.errors.genericError !== undefined) 
                body = this.state.errors.genericError;
            else
            if (this.state.errors.recaptchaError !== undefined)
                body = this.state.errors.recaptchaError;
            
            return body
        }
    }

    render(){
        const { t, i18n } = this.props;
        
        return(
            <main id="Signup" role="main">

                <Header pagename="signup" withoutNavs={false} />

                <section data-pagename="signup">
                    <div className="container section primary withAside mt-0">
                        <div className="row">

                            <div className="col-md-3 col-xs-12">
                                <h4>{ t('section.info.title') }</h4>
                                <p>{ t('section.info.description') }</p>
                            </div>

                            <div className="col-md-9 col-xs-12">
                                <div className="pb-3">

                                    <header>
                                        <h1>{ t('section.title') }</h1>
                                        {
                                            t('section.description') && (
                                                <p>{ t('section.description') }</p>
                                            )
                                        } 
                                    </header>

                                    <form onSubmit={this.handleSubmit}>
                                        
                                        <div className="form-group row">
                                            <label className="col-12 col-form-label">{ t('section.form.firstname') } <sup>*</sup></label>
                                            <div className="col-12">
                                                <input type="text" 
                                                    value={this.state.firstname}
                                                    onChange={(e) => this.setState({ ...this.state, firstname: e.target.value })} 
                                                    id="firstname" 
                                                    className="with-grey-bg w-100" required/>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group row">
                                            <label className="col-12 col-form-label">{ t('section.form.lastname') } <sup>*</sup></label>
                                            <div className="col-12">
                                                <input type="text" 
                                                    value={this.state.lastname}
                                                    onChange={(e) => this.setState({ ...this.state, lastname: e.target.value })} 
                                                    id="lastname" 
                                                    className="with-grey-bg w-100" required/>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group row">
                                            <label className="col-12 col-form-label">{ t('section.form.companyname') } <sup>*</sup></label>
                                            <div className="col-12">
                                                <input type="text" 
                                                    value={this.state.companyname}
                                                    onChange={(e) => this.setState({ ...this.state, companyname: e.target.value })} 
                                                    id="companyname" 
                                                    className="with-grey-bg w-100" required/>
                                            </div>
                                        </div>
                                        
                                        <div className={`form-group row ${this.state.errors.emailError ? 'error' : ''}`}>
                                            <label className="col-12 col-form-label">{ t('section.form.email') } <sup>*</sup></label>
                                            <div className="col-12">
                                                <input type="email" 
                                                    value={this.state.email}
                                                    onChange={(e) => this.setState({ ...this.state, email: e.target.value, username: e.target.value })} 
                                                    id="email" 
                                                    className="with-grey-bg w-100" required/>
                                                {this.state.errors.emailError ? (
                                                    <div className="error"> {this.state.errors.emailError} </div>
                                                ) : ('')}
                                            </div>
                                        </div>

                                        <div className={`form-group row ${this.state.errors.usernameError ? 'error' : ''}`}>
                                            <label className="col-12 col-form-label">{ t('section.form.username') } <sup>*</sup></label>
                                            <div className="col-12">
                                                <input type="text" autoComplete="off" 
                                                    value={this.state.username}
                                                    readOnly={true}
                                                    //onChange={(e) => this.setState({ ...this.state, username: e.target.value })} 
                                                    id="username" 
                                                    className="with-grey-bg w-100" required/>
                                                {this.state.errors.usernameError ? (
                                                    <div className="error"> {this.state.errors.usernameError} </div>
                                                ) : ('')}
                                            </div>
                                        </div>
                                        
                                        <div className="form-group row">
                                            <label className="col-12 col-form-label">{ t('section.form.password') } <sup>*</sup></label>
                                            <div className="col-12">
                                                <input type="password" autoComplete="off" 
                                                    value={this.state.password}
                                                    onChange={(e) => this.setState({ ...this.state, password: e.target.value })} 
                                                    id="password" 
                                                    className="with-grey-bg w-100" required/>
                                            </div>
                                        </div>

                                        <ReCAPTCHA
                                            sitekey={this.props.gConfig.google.reCAPTCHA}
                                            onChange={this.onChange}
                                            className="mb-3"
                                        />

                                        <button disabled={this.state.validate ? false : 'disabled'} type="submit" 
                                                className="btn btn-primary">{ t('section.form.cta_register') }</button>

                                        <div className="terms" dangerouslySetInnerHTML={{ __html: t('section.form.terms.label', { url: `${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/privacy-policy` }) }} />
                                    </form>

                                    <Modal show={this.ManageModal('visible')}
                                        title={this.props.t('section.title')}
                                        body={this.ManageModal('body')} 
                                        onExited={() => this.props.history.push('./login')}
                                        backdrop={false}
                                        />

                                </div>

                            </div>
                            
                        </div>
                    </div>
                </section>

                <Footer i18n={i18n} />
            </main>
        )
    }

}
export default compose(
    graphql(REGISTER, { name: "REGISTER" }),
    graphql(LOGIN_MUTATION)
)( withRouter( withApollo( withNamespaces('signup')(Signup) )))
